<template>
    <header>
      <b-container>
        <h2>Sponge Downloads</h2>
      </b-container>
    </header>
</template>

<script>
  import {BContainer} from 'bootstrap-vue/esm/components/layout/container';

    export default {
    name: 'header',
    components: {
      'b-container': BContainer
    }
  }
</script>
